import { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes, Navigate, useParams} from 'react-router-dom';

import theme from './components/siteTheme';
import { incidentsContext } from './contexts/IncidentsContext';

import Appbar from './components/Branding/Appbar';
import GoogleTranslate from './components/GoogleTranslate';
import Home from './pages/Home';
import Incidents from './pages/Incidents';
import NotFound from './pages/NotFound';

export default function App() {
  const { incidents } = useContext(incidentsContext);

  const MyRedirect = () => {
    const { id } = useParams();
    // return <Navigate to={`/incidents/cyclonefour/${id}`} replace />;

    return <Navigate to={`/incidents/debby/${id}`} replace />;
  };

  return (
    <div>
    <GoogleTranslate></GoogleTranslate>
    <ThemeProvider theme={theme}>
      <Appbar>
        <Routes>
          <Route path='/' element={<Home />} />

          {incidents.length > 0 && (
            <>
              <Route path='incidents' element={<Incidents />} />
              {incidents[0].routes.map((route) => {
                return <Route path={route.path} element={route.element} />;
              })}
            </>
          )}

          <Route path='/incidents/atlanticdisturbance'
            element={<Navigate to="/incidents/cyclonefour"/> }/>

          <Route path='/incidents/atlanticdisturbance/:id'element={<MyRedirect/>}  /> 

          <Route path='/incidents/atlanticdisturbance'
            element={<Navigate to="/incidents/debby"/> }/>

          <Route path='/incidents/atlanticdisturbance/:id'element={<MyRedirect/>}  /> 

           <Route path='/incidents/cyclonefour'
            element={<Navigate to="/incidents/debby"/> }/>

          <Route path='/incidents/cyclonefour/:id'element={<MyRedirect/>}  /> 

          <Route path='*' element={<NotFound />} />
        </Routes>
      </Appbar>
    </ThemeProvider>
    </div>
  );
}
