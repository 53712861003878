/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Divider } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import IncidentLayout from '../components/Branding/IncidentLayout';
import OtherClosuresTable from '../components/Tables/OtherClosures';
import { titleContext } from '../contexts/TitleContext';
import { breadCrumbsContext } from '../contexts/BreadCrumbsContext';
import { incidentsContext } from '../contexts/IncidentsContext';

const TypographyStyles = { fontWeight: 'bold' };

const headCells = [
  { id: 'ClosureName', label: 'Name' },
  { id: 'EffectiveDate', label: 'Effective Date' },
  { id: 'Notes', label: 'Notes' },
];

export default function OtherClosures({ incidentIndex }) {
  const { updatePageTitle, updatePageHeading } = useContext(titleContext);
  const { pushBreadCrumbs } = useContext(breadCrumbsContext);
  const { incidents } = useContext(incidentsContext);

  const [roads, setRoads] = useState([]);

  const getRoadClosures = async () => {
    try {
      const result = await axios(`https://api.alachuacounty.us/incidents-api/otherclosures/active`);
      if (result.data && result.data.length) {
        setRoads(result.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoadClosures();
    updatePageTitle(`${incidents[incidentIndex].name} | Other Closures`);
    updatePageHeading(incidents[incidentIndex].name);
    pushBreadCrumbs({
      crumb: incidents[incidentIndex].name,
      link: `/incidents/${incidents[incidentIndex].urlName}/`,
    });
  }, []);

  return (
    <IncidentLayout title='Closures'>
      <Grid container spacing={3} sx={{ width: '100% !important' }} paddingBottom={6}>
        <Grid item xs={12} sx={{ paddingLeft: '0 !important' }}>
          <Typography variant='h6' sx={TypographyStyles}>
            Other Closures
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: '0 !important' }}>
          <OtherClosuresTable headCells={headCells} data={roads} />
        </Grid>

      </Grid>
    </IncidentLayout>
  );
}
